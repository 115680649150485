import { default as _91id_93kVlayz2B4WMeta } from "/var/www/sites/admin.ultrafedt.dk/releases/20250207085806/pages/connection-drivers/[id].vue?macro=true";
import { default as indexSCYSp7xjUMMeta } from "/var/www/sites/admin.ultrafedt.dk/releases/20250207085806/pages/connection-drivers/index.vue?macro=true";
import { default as indexI6q0e5xWQJMeta } from "/var/www/sites/admin.ultrafedt.dk/releases/20250207085806/pages/index.vue?macro=true";
import { default as mailIHOqVFiwX1Meta } from "/var/www/sites/admin.ultrafedt.dk/releases/20250207085806/pages/mail.vue?macro=true";
import { default as indexOdcGcqOd1aMeta } from "/var/www/sites/admin.ultrafedt.dk/releases/20250207085806/pages/merchants/[id]/index.vue?macro=true";
import { default as merchant_45accesses5okgVSDHfeMeta } from "/var/www/sites/admin.ultrafedt.dk/releases/20250207085806/pages/merchants/[id]/merchant-accesses.vue?macro=true";
import { default as storesFRMsS5r72pMeta } from "/var/www/sites/admin.ultrafedt.dk/releases/20250207085806/pages/merchants/[id]/stores.vue?macro=true";
import { default as terminalsbGeHadQ1ONMeta } from "/var/www/sites/admin.ultrafedt.dk/releases/20250207085806/pages/merchants/[id]/terminals.vue?macro=true";
import { default as _91id_93YVdTEc7QO3Meta } from "/var/www/sites/admin.ultrafedt.dk/releases/20250207085806/pages/merchants/[id].vue?macro=true";
import { default as indexupCkZTYShdMeta } from "/var/www/sites/admin.ultrafedt.dk/releases/20250207085806/pages/merchants/index.vue?macro=true";
import { default as _91id_93PEnvz6pBpGMeta } from "/var/www/sites/admin.ultrafedt.dk/releases/20250207085806/pages/users/[id].vue?macro=true";
import { default as indexpyMFAbKee9Meta } from "/var/www/sites/admin.ultrafedt.dk/releases/20250207085806/pages/users/index.vue?macro=true";
export default [
  {
    name: "connection-drivers-id",
    path: "/connection-drivers/:id()",
    component: () => import("/var/www/sites/admin.ultrafedt.dk/releases/20250207085806/pages/connection-drivers/[id].vue")
  },
  {
    name: "connection-drivers",
    path: "/connection-drivers",
    component: () => import("/var/www/sites/admin.ultrafedt.dk/releases/20250207085806/pages/connection-drivers/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/admin.ultrafedt.dk/releases/20250207085806/pages/index.vue")
  },
  {
    name: "mail",
    path: "/mail",
    component: () => import("/var/www/sites/admin.ultrafedt.dk/releases/20250207085806/pages/mail.vue")
  },
  {
    name: _91id_93YVdTEc7QO3Meta?.name,
    path: "/merchants/:id()",
    component: () => import("/var/www/sites/admin.ultrafedt.dk/releases/20250207085806/pages/merchants/[id].vue"),
    children: [
  {
    name: "merchants-id",
    path: "",
    component: () => import("/var/www/sites/admin.ultrafedt.dk/releases/20250207085806/pages/merchants/[id]/index.vue")
  },
  {
    name: "merchants-id-merchant-accesses",
    path: "merchant-accesses",
    component: () => import("/var/www/sites/admin.ultrafedt.dk/releases/20250207085806/pages/merchants/[id]/merchant-accesses.vue")
  },
  {
    name: "merchants-id-stores",
    path: "stores",
    component: () => import("/var/www/sites/admin.ultrafedt.dk/releases/20250207085806/pages/merchants/[id]/stores.vue")
  },
  {
    name: "merchants-id-terminals",
    path: "terminals",
    component: () => import("/var/www/sites/admin.ultrafedt.dk/releases/20250207085806/pages/merchants/[id]/terminals.vue")
  }
]
  },
  {
    name: "merchants",
    path: "/merchants",
    component: () => import("/var/www/sites/admin.ultrafedt.dk/releases/20250207085806/pages/merchants/index.vue")
  },
  {
    name: "users-id",
    path: "/users/:id()",
    component: () => import("/var/www/sites/admin.ultrafedt.dk/releases/20250207085806/pages/users/[id].vue")
  },
  {
    name: "users",
    path: "/users",
    component: () => import("/var/www/sites/admin.ultrafedt.dk/releases/20250207085806/pages/users/index.vue")
  }
]